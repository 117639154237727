import * as React from "react";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

import Layout from "../../components/Layout";
import Seo from "../../components/Seo";

import NewsHeader from "../../components/NewsHeader";
import SleepyNewsAnchor from "../../components/SleepyNewsAnchor";

const NewsPage = () => (
	<Layout>
		<Seo title="May 2022" />
		<NewsHeader />
		<h2>May 2022</h2>

		<h3 className="blogdate">
			Sunday, May 1, 2022
		</h3>

		<h4 className="blogitemtitle">
			<SleepyNewsAnchor num="1">Upcoming show! House concert & video recording</SleepyNewsAnchor>
		</h4>
		<div className="blogitembody">
			<p>
				Announcing a very special show!
				I will perform a solo house concert in Brasilia at my Little Bakery Studio (in the lounge, actually),
				which will be recorded live to video.
				Please <Link to="/contact/">get in touch with me directly</Link> if you wish to be a part of this very special evening! 🎶📹
				(<span className="italic">Favor, <Link to="/contact/">fique em contato</Link> se quiser assistir essa noite!</span>)
			</p>
			<ul>
				<li>The special date: Thursday, May 12, 2022</li>
				<li>Doors: 7 p.m. (19:00)</li>
				<li>Instagram Live @jesserivest broadcast start: 19:50</li>
				<li>Music and filming begins: 20:00</li>
				<li><span className="bold">Punctuality is very important!</span> 🙏</li>
				<li>Location: the end of Asa Norte, Brasilia, DF</li>
			</ul>
			<p>
				This event is semi-private, in that we've only so much space and we've already confirmed a number of guests.
				However, there likely will be some remaining spaces to fill, and I'd love for you to do that. 😎
			</p>
			<p>
				I will prepare several loaves of sourdough bread, and my partner Jane will prepare hummus.
				You'll bring your own beverage,
				and a cushion to sit on if you can (we have <span className="italic">some</span> seating and some floor space).&nbsp;
				<span className="bold">I will be filming my performance</span>,
				and I need a guest to film a bit of the ambience with their mobile phone.
				We'll work that out before the show starts.
			</p>
			<p>
				Updates will be posted here on this page (above—scroll up) as they come.
			</p>
			<div className="main--centerwrapper p">
				<StaticImage
					src="../../images/photos/news/_MG_1258 from Raquel - 1080px.jpg"
					alt="Jesse Rivest house concert poster May 12 2022"
					placeholder="blurred"
					layout="constrained"
					className="main--centered"
					imgClassName="img--bordered-small"
					width={300}
				/>
			</div>
		</div>

		<p>
		<Link to="/news/">Go back to the news index page</Link>
		</p>
	</Layout>
);

export default NewsPage;
