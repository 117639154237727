import * as React from "react";

import '../styles/components/_sleepyNewsAnchor.scss';

const doNothing = e => e.preventDefault();
const buildAnchor = num => `#${num}`;

const SleepyNewsAnchor = ({num, children}) => (

	<a href={buildAnchor(num)} id={num} className="sleepyNewsAnchor" onClick={doNothing}>
		{children}
	</a>
);

export default SleepyNewsAnchor;